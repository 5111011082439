<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="global_setting">
            <div class="tabs_content">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
                <div class="stat_container">
                    <div class="section_header mt-5 mb-4">
                        <h2>Progress Tracking <span class="tag">{{ProgressScenarioList[selectScenario].title}}</span></h2>
                        <div class="optionDrops contacts-tabs" @click="progressDuration = !progressDuration" v-click-outside="closeProgressDuration">
                            {{ selectProgressFilter }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="progressDuration ? 'active' : ''">
                                <ul>
                                    <li v-for="(duration, d) of progressFilterDuration" :key="d" @click="selectProgressFilter = duration;">{{ duration }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="optionDrops" @click="openFilter = !openFilter;" v-click-outside="closeOpenFilter">
                            Filter
                            <div class="dropdown_wpr progress_filter_bar" :class="openFilter ? 'active' : ''">
                                <div class="scenario_box" @click.stop="scenarioDropdown = !scenarioDropdown;" v-click-outside="closeScenarioDropdown">
                                    {{ProgressScenarioList[selectScenario].title}}<i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="scenarioDropdown ? 'active' : ''">
                                        <ul>
                                            <li v-for="(scenario, s) in ProgressScenarioList" :key="s" @click="selectScenario = s;">{{scenario.title}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="category_wpr">
                                    <div class="category_grp active" v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                                        <h4>{{progress.group_title}}</h4>
                                        <ul>
                                            <li v-for="(item, i) of progress.items" :key="i" @click.stop="item.is_selected = !item.is_selected">
                                                <label :for="`filter-${i}`" class="checkbox">
                                                    <span :class="{'active' : item.is_selected}"><i class="fas fa-check"></i></span>
                                                    <h5>{{ item.name }}</h5>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="report_card">
                        <div class="header">
                            <div class="user_info">
                                <img :src="user.profile_pic" alt="">
                                <div>
                                    <h4>{{ user.full_name }}</h4>
                                    <h5>Started Tracking &nbsp;<span>&#123;&#123;Oct 21, 2022&#125;&#125;</span></h5>
                                </div>
                            </div>
                            <ul class="total_progress" @click="scrollingTo(`progress_history`)">
                                <li>
                                    <h3>24<span>Days</span></h3>
                                    <label class="completed">Completed</label>
                                </li>
                                <li>
                                    <h3>6<span>Days</span></h3>
                                    <label class="missed">Missed</label>
                                </li>
                            </ul>
                        </div>
                        <div class="overview">
                            <template v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                                <ul>
                                    <template v-for="(item, i) of progress.items" :key="i">
                                        <li v-if="item.is_selected" @click="scrollingTo(`${item.title.replaceAll(' ','')}-${i}`)" >
                                            <img :src="require(`@/assets/images/${item.img}`)" :alt="item.name">
                                            <h4>
                                                {{item.title}}
                                                <span v-if="item.average_score">Average <a>{{item.average_score}} {{item.unit.value }}</a> per day</span>
                                                <span v-else>Not available</span>
                                            </h4>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </div>
                    </div>
                    
                    <template v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                        <div v-for="(item, i) of progress.items" :key="i" :id="`${item.title.replaceAll(' ','')}-${i}`">
                            <track-chart :option="item" v-if="item.is_selected"></track-chart>
                        </div>
                    </template>

                    <div class="content_card_wpr show" id="progress_history">
                        <div class="title">
                            <h4>Completed Trackings<label>12</label></h4>
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                    <div class="inner_circle">
                                        <div class="score">50<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="57" />
                                    </svg>
                                </div>
                                <h6>Completion rate</h6>
                            </div>
                            <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                        </div>
                        <div class="section_content">
                            <ul class="list workout_card workout_list">
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 24, 2023</span> Vitamins<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="vitamin-a" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Vitamin A</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="vitamin-b" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Vitamin B1</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="vitamin-c" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Vitamin C</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span>Submitted On Time</span>
                                                        <label>Already submitted on Sept 24, 2023 at 9:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 25, 2023</span> Minerals<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="calcium" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Calcium</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="copper" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Copper</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="iron" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Iron</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="delay">Submitted Late</span>
                                                        <label>Already submitted on Sept 29, 2023 at 7:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 26, 2023</span> Supplements<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="bcaas" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>BCAAs</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="creatine" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Creatine</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="fishoil" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Fish Oil</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="success">Edited After Submission</span>
                                                        <label>Already submitted on Sept 27, 2023 at 9:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="content_card_wpr show">
                        <div class="title">
                            <h4>Missed Trackings<label>12</label></h4>
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                    <div class="inner_circle">
                                        <div class="score">50<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="57" />
                                    </svg>
                                </div>
                                <h6>Completion rate</h6>
                            </div>
                            <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                        </div>
                        <div class="section_content">
                            <ul class="list workout_card workout_list">
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 27, 2023</span> Vitamins<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="vitamin-d" class="checkbox">
                                                                <span></span>
                                                                <p>Vitamin D</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="vitamin-e" class="checkbox">
                                                                <span></span>
                                                                <p>Vitamin E</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="vitamin-k" class="checkbox">
                                                                <span></span>
                                                                <p>Vitamin K</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 28, 2023</span> Minerals<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="calcium" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Calcium</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="copper" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Copper</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="iron" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Iron</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 29, 2023</span> Supplements<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="check_list">
                                                        <li>
                                                            <label for="bcaas" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>BCAAs</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="creatine" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Creatine</p>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="fishoil" class="checkbox">
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>Fish Oil</p>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'

const TrackChart = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/TrackChart'))

export default {
    name: 'Workouts Stats Details',

    data () {
        return{
            progressDuration: false,
            selectProgressFilter: 'Last 7 Days',
            progressFilterDuration:[
                'Last 7 Days',
                'Last 14 Days',
                'Last 30 Days',
                'Last 90 Days',
                'This Year',
                'All Time',
            ],
            openFilter: false,
            scenarioDropdown: false,
            ProgressScenarioList: [
                {
                    title: 'Progress Plan',
                    categories:[
                        {
                            group_title: 'Nutrition',
                            items:[
                                {
                                    name: 'Water',
                                    is_selected: 1,
                                    img: 'water.svg',
                                    title: 'Water',
                                    subtext: 'How much water did you drink?',
                                    unit: {
                                        mode: 'single',
                                        value: 'oz',
                                        options: [
                                            { value: 'oz', label: 'Ounce' },
                                            { value: 'ml', label: 'Milliliters' }
                                        ],
                                    },
                                    average_score: 58,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            // areaStyle: {
                                            //     color: {
                                            //         type: 'linear',
                                            //         x: 0,
                                            //         y: 0,
                                            //         x2: 0,
                                            //         y2: 1,
                                            //         colorStops: [
                                            //             {
                                            //                 offset: 0,
                                            //                 color: 'rgba(47,127,237,0.15)'
                                            //             },
                                            //             {
                                            //                 offset: 1,
                                            //                 color: 'rgba(58,77,233,0)'
                                            //             }
                                            //         ],
                                            //         global: false
                                            //     },
                                            // },
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Calories Consumed',
                                    is_selected: 1,
                                    img: 'calories-consume.svg',
                                    title: 'Calories Consumed',
                                    subtext: 'How many calories have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'kcal',
                                        options: [
                                            { value: 'kcal', label: 'kcal' }
                                        ],
                                    },
                                    average_score: 1500,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fruits',
                                    is_selected: 1,
                                    img: 'fruits.svg',
                                    title: 'Fruits',
                                    subtext: 'How much fruits have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'pcs',
                                        options: [
                                            { value: 'pcs', label: 'Pieces' },
                                            { value: 'servings', label: 'Servings' },
                                            { value: 'cups', label: 'Cups' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'oz', label: 'Ounce' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Vegetables',
                                    is_selected: 1,
                                    img: 'vegetables.svg',
                                    title: 'Vegetables',
                                    subtext: 'How much vegetables have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'pcs',
                                        options: [
                                            { value: 'pcs', label: 'Pieces' },
                                            { value: 'servings', label: 'Servings' },
                                            { value: 'cups', label: 'Cups' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'oz', label: 'Ounce' },
                                        ],
                                    },
                                    average_score: 4,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Macros',
                                    is_selected: 1,
                                    img: 'macros.png',
                                    title: 'Macros',
                                    subtext: 'How much fat, protein, and carbs have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'fists', label: 'Fists' },
                                            { value: 'palms', label: 'Palms' },
                                            { value: 'thumbs', label: 'Thumbs' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Protein',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `P ${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Fat',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `F ${params.value}`;
                                                }
                                            },
                                            data: [25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 50},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 50 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Carbs',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `C ${params.value}`;
                                                }
                                            },
                                            data: [20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 45},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 45 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fat',
                                    is_selected: 1,
                                    img: 'fat.svg',
                                    title: 'Fat',
                                    subtext: 'How much fat have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'fists', label: 'Fists' },
                                            { value: 'palms', label: 'Palms' },
                                            { value: 'thumbs', label: 'Thumbs' },
                                        ],
                                    },
                                    average_score: 25,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Protein',
                                    is_selected: 1,
                                    img: 'protein.svg',
                                    title: 'Protein',
                                    subtext: 'How much protein have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'fists', label: 'Fists' },
                                            { value: 'palms', label: 'Palms' },
                                            { value: 'thumbs', label: 'Thumbs' },
                                        ],
                                    },
                                    average_score: 30,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Carbohydrates',
                                    is_selected: 1,
                                    img: 'carbohydrates.svg',
                                    title: 'Carbohydrates',
                                    subtext: 'How much carbs have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'gm', label: 'Grams' },
                                            { value: 'fists', label: 'Fists' },
                                            { value: 'palms', label: 'Palms' },
                                            { value: 'thumbs', label: 'Thumbs' },
                                        ],
                                    },
                                    average_score: 30,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Sugar',
                                    is_selected: 1,
                                    img: 'sugar.png',
                                    title: 'Sugar',
                                    subtext: 'How much sugar have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: 'gm', label: 'Grams' },
                                        ],
                                    },
                                    average_score: 30,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Sugar Alcohols',
                                    is_selected: 1,
                                    img: 'sugar-alcohol.svg',
                                    title: 'Sugar Alcohols',
                                    subtext: 'How much sugar alcohol have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: 'gm', label: 'Grams' }
                                        ],
                                    },
                                    average_score: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fiber',
                                    is_selected: 1,
                                    img: 'fiber.png',
                                    title: 'Fiber',
                                    subtext: 'How much fiber have you consumed today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'gm',
                                        options: [
                                            { value: 'gm', label: 'Grams' }
                                        ],
                                    },
                                    average_score: 40,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Vitamins',
                                    is_selected: 1,
                                    img: 'vitamins.png',
                                    title: 'Vitamins',
                                    subtext: 'Can you please mark-check all the vitamins you took today?',
                                    isGoalRange: 1,
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'}`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Vitamin K', 'Vitamin E', 'Vitamin D', 'Vitamin C', 'Vitamin B1', 'Vitamin A'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 1,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ["#e9e9e9", "#2f7eed50"]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Vitamin K',
                                                type: 'heatmap',
                                                data: [[0, 0, 1], [1, 0, 0], [2, 0, 1], [3, 0, 1], [4, 0, 0], [5, 0, 1], [6, 0, 1],[7, 0, 1], [8, 0, 0], [9, 0, 1], [10, 0, 1], [11, 0, 0], [12, 0, 1], [13, 0, 1], [14, 0, 1], [15, 0, 0], [16, 0, 1], [17, 0, 1], [18, 0, 0], [19, 0, 1], [20, 0, 1], [21, 0, 1], [22, 0, 0], [23, 0, 1], [24, 0, 1], [25, 0, 0], [26, 0, 1], [27, 0, 1], [28, 0, 1], [29, 0, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Vitamin E',
                                                type: 'heatmap',
                                                data: [[0, 1, 1], [1, 1, 1], [2, 1, 0], [3, 1, 0], [4, 1, 1], [5, 1, 1], [6, 1, 1], [7, 1, 1], [8, 1, 1], [9, 1, 0], [10, 1, 0], [11, 1, 1], [12, 1, 1], [13, 1, 1], [14, 1, 1], [15, 1, 1], [16, 1, 0], [17, 1, 0], [18, 1, 1], [19, 1, 1], [20, 1, 1], [21, 1, 1], [22, 1, 1], [23, 1, 0], [24, 1, 0], [25, 1, 1], [26, 1, 1], [27, 1, 1], [28, 1, 1], [29, 1, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Vitamin D',
                                                type: 'heatmap',
                                                data: [[0, 2, 1], [1, 2, 0], [2, 2, 1], [3, 2, 0], [4, 2, 1], [5, 2, 0], [6, 2, 1], [7, 2, 1], [8, 2, 0], [9, 2, 1], [10, 2, 0], [11, 2, 1], [12, 2, 0], [13, 2, 1], [14, 2, 1], [15, 2, 0], [16, 2, 1], [17, 2, 0], [18, 2, 1], [19, 2, 0], [20, 2, 1], [21, 2, 1], [22, 2, 0], [23, 2, 1], [24, 2, 0], [25, 2, 1], [26, 2, 0], [27, 2, 1], [28, 2, 1], [29, 2, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Vitamin C',
                                                type: 'heatmap',
                                                data: [[0, 3, 0], [1, 3, 1], [2, 3, 1], [3, 3, 1], [4, 3, 0], [5, 3, 1], [6, 3, 1], [7, 3, 0], [8, 3, 1], [9, 3, 1], [10, 3, 1], [11, 3, 0], [12, 3, 1], [13, 3, 1], [14, 3, 0], [15, 3, 1], [16, 3, 1], [17, 3, 1], [18, 3, 0], [19, 3, 1], [20, 3, 1], [21, 3, 0], [22, 3, 1], [23, 3, 1], [24, 3, 1], [25, 3, 0], [26, 3, 1], [27, 3, 1], [28, 3, 0], [29, 3, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Vitamin B1',
                                                type: 'heatmap',
                                                data: [[0, 4, 1], [1, 4, 1], [2, 4, 0], [3, 4, 0], [4, 4, 0], [5, 4, 1], [6, 4, 1], [7, 4, 1], [8, 4, 1], [9, 4, 0], [10, 4, 0], [11, 4, 0], [12, 4, 1], [13, 4, 1], [14, 4, 1], [15, 4, 1], [16, 4, 0], [17, 4, 0], [18, 4, 0], [19, 4, 1], [20, 4, 1], [21, 4, 1], [22, 4, 1], [23, 4, 0], [24, 4, 0], [25, 4, 0], [26, 4, 1], [27, 4, 1], [28, 4, 1], [29, 4, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Vitamin A',
                                                type: 'heatmap',
                                                data: [[0, 5, 1], [1, 5, 1], [2, 5, 1], [3, 5, 0], [4, 5, 1], [5, 5, 1], [6, 5, 1], [7, 5, 1], [8, 5, 1], [9, 5, 1], [10, 5, 0], [11, 5, 1], [12, 5, 1], [13, 5, 1], [14, 5, 1], [15, 5, 1], [16, 5, 1], [17, 5, 0], [18, 5, 1], [19, 5, 1], [20, 5, 1], [21, 5, 1], [22, 5, 1], [23, 5, 1], [24, 5, 0], [25, 5, 1], [26, 5, 1], [27, 5, 1],[28, 5, 1], [29, 5, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    name: 'Minerals',
                                    is_selected: 1,
                                    img: 'minerals.png',
                                    title: 'Minerals',
                                    subtext: 'Check the minerals you have consumed today',
                                    isGoalRange: 1,
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'}`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Zinc', 'Potassium', 'Magnesium', 'Iron', 'Copper', 'Calcium'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 1,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ["#e9e9e9", "#2f7eed50"]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Zinc',
                                                type: 'heatmap',
                                                data: [[0, 0, 1], [1, 0, 0], [2, 0, 1], [3, 0, 1], [4, 0, 0], [5, 0, 1], [6, 0, 1],[7, 0, 1], [8, 0, 0], [9, 0, 1], [10, 0, 1], [11, 0, 0], [12, 0, 1], [13, 0, 1], [14, 0, 1], [15, 0, 0], [16, 0, 1], [17, 0, 1], [18, 0, 0], [19, 0, 1], [20, 0, 1], [21, 0, 1], [22, 0, 0], [23, 0, 1], [24, 0, 1], [25, 0, 0], [26, 0, 1], [27, 0, 1], [28, 0, 1], [29, 0, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Potassium',
                                                type: 'heatmap',
                                                data: [[0, 1, 1], [1, 1, 1], [2, 1, 0], [3, 1, 0], [4, 1, 1], [5, 1, 1], [6, 1, 1], [7, 1, 1], [8, 1, 1], [9, 1, 0], [10, 1, 0], [11, 1, 1], [12, 1, 1], [13, 1, 1], [14, 1, 1], [15, 1, 1], [16, 1, 0], [17, 1, 0], [18, 1, 1], [19, 1, 1], [20, 1, 1], [21, 1, 1], [22, 1, 1], [23, 1, 0], [24, 1, 0], [25, 1, 1], [26, 1, 1], [27, 1, 1], [28, 1, 1], [29, 1, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Magnesium',
                                                type: 'heatmap',
                                                data: [[0, 2, 1], [1, 2, 0], [2, 2, 1], [3, 2, 0], [4, 2, 1], [5, 2, 0], [6, 2, 1], [7, 2, 1], [8, 2, 0], [9, 2, 1], [10, 2, 0], [11, 2, 1], [12, 2, 0], [13, 2, 1], [14, 2, 1], [15, 2, 0], [16, 2, 1], [17, 2, 0], [18, 2, 1], [19, 2, 0], [20, 2, 1], [21, 2, 1], [22, 2, 0], [23, 2, 1], [24, 2, 0], [25, 2, 1], [26, 2, 0], [27, 2, 1], [28, 2, 1], [29, 2, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Iron',
                                                type: 'heatmap',
                                                data: [[0, 3, 0], [1, 3, 1], [2, 3, 1], [3, 3, 1], [4, 3, 0], [5, 3, 1], [6, 3, 1], [7, 3, 0], [8, 3, 1], [9, 3, 1], [10, 3, 1], [11, 3, 0], [12, 3, 1], [13, 3, 1], [14, 3, 0], [15, 3, 1], [16, 3, 1], [17, 3, 1], [18, 3, 0], [19, 3, 1], [20, 3, 1], [21, 3, 0], [22, 3, 1], [23, 3, 1], [24, 3, 1], [25, 3, 0], [26, 3, 1], [27, 3, 1], [28, 3, 0], [29, 3, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Copper',
                                                type: 'heatmap',
                                                data: [[0, 4, 1], [1, 4, 1], [2, 4, 0], [3, 4, 0], [4, 4, 0], [5, 4, 1], [6, 4, 1], [7, 4, 1], [8, 4, 1], [9, 4, 0], [10, 4, 0], [11, 4, 0], [12, 4, 1], [13, 4, 1], [14, 4, 1], [15, 4, 1], [16, 4, 0], [17, 4, 0], [18, 4, 0], [19, 4, 1], [20, 4, 1], [21, 4, 1], [22, 4, 1], [23, 4, 0], [24, 4, 0], [25, 4, 0], [26, 4, 1], [27, 4, 1], [28, 4, 1], [29, 4, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Calcium',
                                                type: 'heatmap',
                                                data: [[0, 5, 1], [1, 5, 1], [2, 5, 1], [3, 5, 0], [4, 5, 1], [5, 5, 1], [6, 5, 1], [7, 5, 1], [8, 5, 1], [9, 5, 1], [10, 5, 0], [11, 5, 1], [12, 5, 1], [13, 5, 1], [14, 5, 1], [15, 5, 1], [16, 5, 1], [17, 5, 0], [18, 5, 1], [19, 5, 1], [20, 5, 1], [21, 5, 1], [22, 5, 1], [23, 5, 1], [24, 5, 0], [25, 5, 1], [26, 5, 1], [27, 5, 1],[28, 5, 1], [29, 5, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            }
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    name: 'Supplements',
                                    is_selected: 1,
                                    img: 'supplement.svg',
                                    title: 'Supplements',
                                    subtext: 'Check the supplements you have consumed today',
                                    isGoalRange: 1,
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'}`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Fish Oil', 'Creatine', 'BCAAs'],
                                            splitArea: {
                                                show: true
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 1,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ["#e9e9e9", "#2f7eed50"]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Fish Oil',
                                                type: 'heatmap',
                                                data: [[0, 0, 1], [1, 0, 1], [2, 0, 0], [3, 0, 0], [4, 0, 0], [5, 0, 1], [6, 0, 1], [7, 0, 1], [8, 0, 1], [9, 0, 0], [10, 0, 0], [11, 0, 0], [12, 0, 1], [13, 0, 1], [14, 0, 1], [15, 0, 1], [16, 0, 0], [17, 0, 0], [18, 0, 0], [19, 0, 1], [20, 0, 1], [21, 0, 1], [22, 0, 1], [23, 0, 0], [24, 0, 0], [25, 0, 0], [26, 0, 1], [27, 0, 1], [28, 0, 1], [29, 0, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Creatine',
                                                type: 'heatmap',
                                                data: [[0, 1, 1], [1, 1, 0], [2, 1, 1], [3, 1, 0], [4, 1, 1], [5, 1, 0], [6, 1, 1], [7, 1, 1], [8, 1, 0], [9, 1, 1], [10, 1, 0], [11, 1, 1], [12, 1, 0], [13, 1, 1], [14, 1, 1], [15, 1, 0], [16, 1, 1], [17, 1, 0], [18, 1, 1], [19, 1, 0], [20, 1, 1], [21, 1, 1], [22, 1, 0], [23, 1, 1], [24, 1, 0], [25, 1, 1], [26, 1, 0], [27, 1, 1], [28, 1, 1], [29, 1, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'BCAAs',
                                                type: 'heatmap',
                                                data: [[0, 2, 1], [1, 2, 1], [2, 2, 1], [3, 2, 0], [4, 2, 1], [5, 2, 1], [6, 2, 1], [7, 2, 1], [8, 2, 1], [9, 2, 1], [10, 2, 0], [11, 2, 1], [12, 2, 1], [13, 2, 1], [14, 2, 1], [15, 2, 1], [16, 2, 1], [17, 2, 0], [18, 2, 1], [19, 2, 1], [20, 2, 1], [21, 2, 1], [22, 2, 1], [23, 2, 1], [24, 2, 0], [25, 2, 1], [26, 2, 1], [27, 2, 1],[28, 2, 1], [29, 2, 1]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    title: 'Brian Wed Demo Test',
                    categories:[
                        {
                            group_title: 'Exercise',
                            items:[
                                {
                                    name: 'Readiness',
                                    is_selected: 0,
                                    img: 'readiness.svg',
                                    title: 'Readiness',
                                    subtext: 'What\'s your readiness level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Step Count',
                                    is_selected: 0,
                                    img: 'steps.svg',
                                    title: 'Step Count',
                                    subtext: 'How many steps did you take today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'steps',
                                        options: [
                                            { value: 'steps', label: 'Steps' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Distance',
                                    is_selected: 0,
                                    img: 'distance.svg',
                                    title: 'Distance',
                                    subtext: 'How much distance did you cover today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'km',
                                        options: [
                                            { value: 'km', label: 'Kilometers' },
                                            { value: 'mi', label: 'Miles' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Workout',
                                    is_selected: 0,
                                    img: 'workouts.svg',
                                    title: 'Workouts',
                                    subtext: 'Select all the workouts you completed today.',
                                    isGoalRange: 1,
                                    unit: {
                                        mode: 'single',
                                        value: 'min',
                                        options: [
                                            { value: 'hr', label: 'Hour' },
                                            { value: 'min', label: 'Minutes' }
                                        ],
                                    },
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2]}`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Conditioning', 'Strength', 'Sport', 'Mobility', 'Recovery', 'Breathwork', 'Mindfulness', 'Other'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 10,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ['#2f7eed10', '#2f7eed',]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Conditioning',
                                                type: 'heatmap',
                                                data: [[0, 0, 8], [1, 0, 8], [2, 0, 8], [3, 0, 7], [4, 0, 7], [5, 0, 7], [6, 0, 6], [7, 0, 6], [8, 0, 6], [9, 0, 5], [10, 0, 5], [11, 0, 5], [12, 0, 4], [13, 0, 4], [14, 0, 4], [15, 0, 3], [16, 0, 3], [17, 0, 3], [18, 0, 2], [19, 0, 2], [20, 0, 2], [21, 0, 1], [22, 0, 1], [23, 0, 1], [24, 0, 0], [25, 0, 0], [26, 0, 0], [27, 0, 0], [28, 0, 0], [29, 0, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Strength',
                                                type: 'heatmap',
                                                data: [[0, 1, 7], [1, 1, 7], [2, 1, 7], [3, 1, 6], [4, 1, 6], [5, 1, 6], [6, 1, 5], [7, 1, 5], [8, 1, 5], [9, 1, 4], [10, 1, 4], [11, 1, 3], [12, 1, 3], [13, 1, 3], [14, 1, 2], [15, 1, 2], [16, 1, 2], [17, 1, 1], [18, 1, 0], [19, 1, 0], [20, 1, 0], [21, 1, 0], [22, 1, 0], [23, 1, 0], [24, 1, 0], [25, 1, 0], [26, 1, 0], [27, 1, 0], [28, 1, 0], [29, 1, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Sport',
                                                type: 'heatmap',
                                                data: [[0, 2, 6], [1, 2, 6], [2, 2, 6], [3, 2, 5], [4, 2, 5], [5, 2, 5], [6, 2, 4], [7, 2, 4], [8, 2, 4], [9, 2, 3], [10, 2, 3], [11, 2, 3], [12, 2, 2], [13, 2, 2], [14, 2, 2], [15, 2, 1], [16, 2, 1], [17, 2, 1], [18, 2, 0], [19, 2, 0], [20, 2, 0], [21, 2, 0], [22, 2, 0], [23, 2, 0], [24, 2, 0], [25, 2, 0], [26, 2, 0], [27, 2, 0], [28, 2, 0], [29, 2, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Mobility',
                                                type: 'heatmap',
                                                data: [[0, 3, 8], [1, 3, 8], [2, 3, 8], [3, 3, 7], [4, 3, 7], [5, 3, 7], [6, 3, 6], [7, 3, 6], [8, 3, 6], [9, 3, 5], [10, 3, 5], [11, 3, 5], [12, 3, 4], [13, 3, 4], [14, 3, 4], [15, 3, 3], [16, 3, 3], [17, 3, 3], [18, 3, 2], [19, 3, 2], [20, 3, 2], [21, 3, 1], [22, 3, 1], [23, 3, 1], [24, 3, 0], [25, 3, 0], [26, 3, 0], [27, 3, 0], [28, 3, 0], [29, 3, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Recovery',
                                                type: 'heatmap',
                                                data: [[0, 4, 7], [1, 4, 7], [2, 4, 7], [3, 4, 6], [4, 4, 6], [5, 4, 6], [6, 4, 5], [7, 4, 5], [8, 4, 5], [9, 4, 4], [10, 4, 4], [11, 4, 3], [12, 4, 3], [13, 4, 3], [14, 4, 2], [15, 4, 2], [16, 4, 2], [17, 4, 1], [18, 4, 0], [19, 4, 0], [20, 4, 0], [21, 4, 0], [22, 4, 0], [23, 4, 0], [24, 4, 0], [25, 4, 0], [26, 4, 0], [27, 4, 0], [28, 4, 0], [29, 4, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Breathwork',
                                                type: 'heatmap',
                                                data: [[0, 5, 6], [1, 5, 6], [2, 5, 6], [3, 5, 5], [4, 5, 5], [5, 5, 5], [6, 5, 4], [7, 5, 4], [8, 5, 4], [9, 5, 3], [10, 5, 3], [11, 5, 3], [12, 5, 2], [13, 5, 2], [14, 5, 2], [15, 5, 1], [16, 5, 1], [17, 5, 1], [18, 5, 0], [19, 5, 0], [20, 5, 0], [21, 5, 0], [22, 5, 0], [23, 5, 0], [24, 5, 0], [25, 5, 0], [26, 5, 0], [27, 5, 0], [28, 5, 0], [29, 5, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Mindfulness',
                                                type: 'heatmap',
                                                data: [[0, 6, 7], [1, 6, 7], [2, 6, 7], [3, 6, 6], [4, 6, 6], [5, 6, 6], [6, 6, 5], [7, 6, 5], [8, 6, 5], [9, 6, 4], [10, 6, 4], [11, 6, 3], [12, 6, 3], [13, 6, 3], [14, 6, 2], [15, 6, 2], [16, 6, 2], [17, 6, 1], [18, 6, 0], [19, 6, 0], [20, 6, 0], [21, 6, 0], [22, 6, 0], [23, 6, 0], [24, 6, 0], [25, 6, 0], [26, 6, 0], [27, 6, 0], [28, 6, 0], [29, 6, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Other',
                                                type: 'heatmap',
                                                data: [[0, 7, 6], [1, 7, 6], [2, 7, 6], [3, 7, 5], [4, 7, 5], [5, 7, 5], [6, 7, 4], [7, 7, 4], [8, 7, 4], [9, 7, 3], [10, 7, 3], [11, 7, 3], [12, 7, 2], [13, 7, 2], [14, 7, 2], [15, 7, 1], [16, 7, 1], [17, 7, 1], [18, 7, 0], [19, 7, 0], [20, 7, 0], [21, 7, 0], [22, 7, 0], [23, 7, 0], [24, 7, 0], [25, 7, 0], [26, 7, 0], [27, 7, 0], [28, 7, 0], [29, 7, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    name: 'Calories Burned',
                                    is_selected: 0,
                                    img: 'calories.png',
                                    title: 'Calories Burned',
                                    subtext: 'How many calories have you burned today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'kcal',
                                        options: [
                                            { value: 'kcal', label: 'kcal' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Zones',
                                    is_selected: 0,
                                    img: 'zone.svg',
                                    title: 'Your Heart Rate Zones',
                                    subtext: 'What\'s your heart rate zones?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'min', label: 'Minutes' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Fat Loss',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 40},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 40 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Cardio',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 35},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 35 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Peak',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 25},
                                                    {name: 'Min', yAxis: 15},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Resting HR',
                                    is_selected: 0,
                                    img: 'resting-heart-rate.svg',
                                    title: 'Resting Heart Rate',
                                    subtext: 'What\'s your resting heart rate today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'bpm',
                                        options: [
                                            { value: 'bpm', label: 'bpm' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Blood Pressure',
                                    is_selected: 0,
                                    img: 'blood-pressure.svg',
                                    title: 'Blood Pressure',
                                    subtext: 'What\'s your blood pressure today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mmHg',
                                        options: [
                                            { value: 'mmHg', label: 'mmHg' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Systolic',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 120},
                                                    {name: 'Min', yAxis: 110},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 120 },
                                                        { yAxis: 110 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Diastolic',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 80},
                                                    {name: 'Min', yAxis: 70},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 80 },
                                                        { yAxis: 70 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        },
                                    ]
                                },
                                {
                                    name: 'HR Variability',
                                    is_selected: 0,
                                    img: 'heart-rate-variability.svg',
                                    title: 'HR Variability',
                                    subtext: 'What\'s your heart rate variability today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ms',
                                        options: [
                                            { value: 'ms', label: 'Milliseconds' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'O2 Saturation',
                                    is_selected: 0,
                                    img: 'oxygen-saturation.svg',
                                    title: 'O2 Saturation',
                                    subtext: 'What\'s your oxygen saturation today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'VO2 Max',
                                    is_selected: 0,
                                    img: 'vo2-max.svg',
                                    title: 'VO2 Max',
                                    subtext: 'What\'s your VO2 Max today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ml/kg/min',
                                        options: [
                                            { value: 'ml/kg/min', label: 'ml/kg/min' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Breathing Rate',
                                    is_selected: 0,
                                    img: 'respiration-rate.svg',
                                    title: 'Breathing Rate',
                                    subtext: 'What\'s your breathing rate today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'bpm',
                                        options: [
                                            { value: 'bpm', label: 'bpm' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Pulse Wave Velocity',
                                    is_selected: 0,
                                    img: 'heart-rate-variability.svg',
                                    title: 'Pulse Wave Velocity',
                                    subtext: 'What\'s your pulse wave velocity today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'pwv',
                                        options: [
                                            { value: 'pwv', label: 'pwv' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Recovery',
                                    is_selected: 0,
                                    img: 'recovery.svg',
                                    title: 'Recovery',
                                    subtext: 'What\'s your recovery scale',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                }
                            ]
                        },
                    ]
                },
                {
                    title: 'Your Emotion Test',
                    categories:[
                        {
                            group_title: 'Emotions',
                            items:[
                                {
                                    name: 'Stress',
                                    is_selected: 0,
                                    img: 'stress.svg',
                                    title: 'Stress',
                                    subtext: 'What\'s your stress level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Mood',
                                    is_selected: 0,
                                    img: 'mood.svg',
                                    title: 'Mood',
                                    subtext: 'What\'s your mood today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Energy',
                                    is_selected: 0,
                                    img: 'energy.svg',
                                    has_show: 0,
                                    title: 'Energy',
                                    subtext: 'What\'s your energy level today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Libido',
                                    is_selected: 0,
                                    img: 'libido.svg',
                                    title: 'Libido',
                                    subtext: 'What\'s your libido level today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fulfillment',
                                    is_selected: 0,
                                    img: 'fulfillment.svg',
                                    title: 'Fulfillment',
                                    subtext: 'What\'s your level of fulfillment today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Motivation',
                                    is_selected: 0,
                                    img: 'motivation.svg',
                                    title: 'Motivation',
                                    subtext: 'What\'s your Motivation level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Confidence',
                                    is_selected: 0,
                                    img: 'confidence.svg',
                                    title: 'Confidence',
                                    subtext: 'What\'s your confidence level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Anger',
                                    is_selected: 0,
                                    img: 'anger.svg',
                                    title: 'Anger',
                                    subtext: 'What\'s your anger level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Happiness',
                                    is_selected: 0,
                                    img: 'fulfillment.svg',
                                    title: 'Happiness',
                                    subtext: 'What\'s your happiness level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        },
                    ]
                },
                {
                    title: 'Your Digestion Results',
                    categories:[
                        {
                            group_title: 'Digestion',
                            items:[
                                {
                                    name: 'Hunger',
                                    is_selected: 0,
                                    img: 'hunger.svg',
                                    title: 'Hunger',
                                    subtext: 'What\'s your level of hunger today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fullness',
                                    is_selected: 0,
                                    img: 'fullness.svg',
                                    has_show: 0,
                                    title: 'Fullness',
                                    subtext: 'What\'s your fullness level today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Bloat',
                                    is_selected: 0,
                                    img: 'bloat.svg',
                                    title: 'Bloat',
                                    subtext: 'What\'s your bloat level today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Stool',
                                    is_selected: 0,
                                    img: 'stool.svg',
                                    title: 'Stool',
                                    subtext: 'What\'s your stool like today?',
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [5, 4, 3, 4, 3, 2, 1, 5, 4, 3, 4, 3, 2, 1, 5, 4, 3, 4, 3, 2, 1, 5, 4, 3, 4, 3, 2, 1, 5, 4],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 4},
                                                    {name: 'Min', yAxis: 3},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 4 },
                                                        { yAxis: 3 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Pain Results',
                    categories:[
                        {
                            group_title: 'Pain',
                            items:[
                                {
                                    name: 'Pain',
                                    is_selected: 0,
                                    img: 'pain.svg',
                                    title: 'Pain',
                                    subtext: 'In which area of your body do you feel pain?',
                                    isGoalRange: 1,
                                    positions:[
                                        ['Left', 'Superior', 'Lateral'],
                                        ['Right', 'Inferior', 'Lateral'],
                                        ['Right', 'Superior', 'Medial'],
                                    ],
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2]}/10`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Shoulder', 'Back', 'Arm'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 10,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ['#2f7eed10', '#2f7eed',]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Shoulder',
                                                type: 'heatmap',
                                                data: [[0, 0, 8], [1, 0, 8], [2, 0, 8], [3, 0, 7], [4, 0, 7], [5, 0, 7], [6, 0, 6], [7, 0, 6], [8, 0, 6], [9, 0, 5], [10, 0, 5], [11, 0, 5], [12, 0, 4], [13, 0, 4], [14, 0, 4], [15, 0, 3], [16, 0, 3], [17, 0, 3], [18, 0, 2], [19, 0, 2], [20, 0, 2], [21, 0, 1], [22, 0, 1], [23, 0, 1], [24, 0, 0], [25, 0, 0], [26, 0, 0], [27, 0, 0], [28, 0, 0], [29, 0, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Back',
                                                type: 'heatmap',
                                                data: [[0, 1, 7], [1, 1, 7], [2, 1, 7], [3, 1, 6], [4, 1, 6], [5, 1, 6], [6, 1, 5], [7, 1, 5], [8, 1, 5], [9, 1, 4], [10, 1, 4], [11, 1, 3], [12, 1, 3], [13, 1, 3], [14, 1, 2], [15, 1, 2], [16, 1, 2], [17, 1, 1], [18, 1, 0], [19, 1, 0], [20, 1, 0], [21, 1, 0], [22, 1, 0], [23, 1, 0], [24, 1, 0], [25, 1, 0], [26, 1, 0], [27, 1, 0], [28, 1, 0], [29, 1, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Arm',
                                                type: 'heatmap',
                                                data: [[0, 2, 6], [1, 2, 6], [2, 2, 6], [3, 2, 5], [4, 2, 5], [5, 2, 5], [6, 2, 4], [7, 2, 4], [8, 2, 4], [9, 2, 3], [10, 2, 3], [11, 2, 3], [12, 2, 2], [13, 2, 2], [14, 2, 2], [15, 2, 1], [16, 2, 1], [17, 2, 1], [18, 2, 0], [19, 2, 0], [20, 2, 0], [21, 2, 0], [22, 2, 0], [23, 2, 0], [24, 2, 0], [25, 2, 0], [26, 2, 0], [27, 2, 0], [28, 2, 0], [29, 2, 0]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    name: 'Inflammation',
                                    is_selected: 0,
                                    img: 'inflammation.svg',
                                    title: 'Inflammation',
                                    subtext: 'What\'s your inflammation level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Mobility',
                                    is_selected: 0,
                                    img: 'mobility.svg',
                                    title: 'Mobility',
                                    subtext: 'What\'s your mobility level today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'Ratings',
                                        options: [
                                            { value: 'Ratings', label: 'Ratings' },
                                            { value: 'Number', label: 'Number' },
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    max_val: 10,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 8},
                                                    {name: 'Min', yAxis: 6},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 8 },
                                                        { yAxis: 6 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Sleep Results',
                    categories:[
                        {
                            group_title: 'Sleep',
                            items:[
                                {
                                    name: 'Sleep',
                                    is_selected: 0,
                                    img: 'sleep.svg',
                                    title: 'Sleep',
                                    subtext: 'How did you sleep?',
                                    isGoalRange: 1,
                                    unit: {
                                        mode: 'single',
                                        value: 'min',
                                        options: [
                                            { value: 'hrs', label: 'Hours' },
                                            { value: 'min', label: 'Minutes' }
                                        ],
                                    },
                                    chart:{
                                        tooltip: {
                                            position: 'top',
                                            formatter: function(params) {
                                                return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2]}`;
                                            },
                                            textStyle: {
                                                fontSize: 11,
                                                color: '#5a5a5a',
                                            },
                                        },
                                        animation: false,
                                        grid: {
                                            top: '2%',
                                            left: '2%',
                                            right: '2%',
                                            bottom: 80,
                                            containLabel: true,
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {
                                            type: 'category',
                                            data: ['Duration', 'Quality', 'Deep Sleep', 'REM Sleep', 'Light Sleep'],
                                            splitArea: {
                                                show: false
                                            },
                                            axisTick: {
                                                show: false,
                                            },
                                        },
                                        visualMap: {
                                            show: false,
                                            min: 0,
                                            max: 10,
                                            calculable: true,
                                            orient: 'horizontal',
                                            left: 'center',
                                            bottom: '15%',
                                            inRange: {
                                                color: ['#2f7eed10', '#2f7eed',]
                                            }
                                        },
                                        series: [
                                            {
                                                name: 'Duration',
                                                type: 'heatmap',
                                                data: [[0, 0, 8], [1, 0, 8], [2, 0, 8], [3, 0, 7], [4, 0, 7], [5, 0, 7], [6, 0, 8], [7, 0, 8], [8, 0, 8], [9, 0, 8], [10, 0, 8], [11, 0, 8], [12, 0, 8], [13, 0, 8], [14, 0, 8], [15, 0, 8], [16, 0, 8], [17, 0, 8], [18, 0, 8], [19, 0, 8], [20, 0, 8], [21, 0, 8], [22, 0, 8], [23, 0, 8], [24, 0, 8], [25, 0, 8], [26, 0, 8], [27, 0, 8], [28, 0, 8], [29, 0, 8]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Quality',
                                                type: 'heatmap',
                                                data: [[0, 1, 7], [1, 1, 7], [2, 1, 7], [3, 1, 6], [4, 1, 6], [5, 1, 6], [6, 1, 5], [7, 1, 5], [8, 1, 5], [9, 1, 4], [10, 1, 7], [11, 1, 7], [12, 1, 7], [13, 1, 6], [14, 1, 6], [15, 1, 6], [16, 1, 5], [17, 1, 5], [18, 1, 5], [19, 1, 6], [20, 1, 6], [21, 1, 6], [22, 1, 5], [23, 1, 5], [24, 1, 5], [25, 1, 7], [26, 1, 7], [27, 1, 7], [28, 1, 6], [29, 1, 6]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Deep Sleep',
                                                type: 'heatmap',
                                                data: [[0, 2, 3], [1, 2, 3], [2, 2, 3], [3, 2, 2], [4, 2, 2], [5, 2, 2], [6, 2, 2], [7, 2, 2], [8, 2, 2], [9, 2, 4], [10, 2, 4], [11, 2, 4], [12, 2, 3], [13, 2, 3], [14, 2, 3], [15, 2, 2], [16, 2, 2], [17, 2, 2], [18, 2, 4], [19, 2, 4], [20, 2, 4], [21, 2, 1], [22, 2, 1], [23, 2, 1], [24, 2, 3], [25, 2, 3], [26, 2, 3], [27, 2, 2], [28, 2, 2], [29, 2, 2]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'REM Sleep',
                                                type: 'heatmap',
                                                data: [[0, 3, 2], [1, 3, 2], [2, 3, 2], [3, 3, 4], [4, 3, 4], [5, 3, 4], [6, 3, 3], [7, 3, 3], [8, 3, 3], [9, 3, 2], [10, 3, 2], [11, 3, 2], [12, 3, 2], [13, 3, 2], [14, 3, 2], [15, 3, 3], [16, 3, 3], [17, 3, 3], [18, 3, 2], [19, 3, 2], [20, 3, 2], [21, 3, 4], [22, 3, 4], [23, 3, 4], [24, 3, 2], [25, 3, 2], [26, 3, 2], [27, 3, 3], [28, 3, 3], [29, 3, 3]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'Light Sleep',
                                                type: 'heatmap',
                                                data: [[0, 4, 3], [1, 4, 3], [2, 4, 3], [3, 4, 2], [4, 4, 2], [5, 4, 2], [6, 4, 3], [7, 4, 3], [8, 4, 3], [9, 4, 2], [10, 4, 2], [11, 4, 2], [12, 4, 3], [13, 4, 3], [14, 4, 3], [15, 4, 3], [16, 4, 3], [17, 4, 3], [18, 4, 2], [19, 4, 2], [20, 4, 2], [21, 4, 3], [22, 4, 3], [23, 4, 3], [24, 4, 3], [25, 4, 3], [26, 4, 3], [27, 4, 3], [28, 4, 3], [29, 4, 3]],
                                                label: {
                                                    show: true,
                                                    color: '#5a5a5a',
                                                    formatter: function(value) {
                                                        return `${value.data[2]}`;
                                                    }
                                                },
                                                itemStyle: {
                                                    borderWidth: 3,
                                                    borderColor: '#fff'
                                                },
                                                emphasis: {
                                                    itemStyle: {
                                                        opacity: 1,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            },
                                        ],
                                        dataZoom: [{
                                            start: 0,
                                            end: 22,
                                            moveHandleSize: 10,
                                            moveHandleStyle: {
                                                color: "#999",
                                            },
                                            height: 0,
                                            showDataShadow: false,
                                            showDetail: false,
                                            handleStyle: {
                                                opacity: 0
                                            },
                                        }],
                                        media: [
                                            {
                                                query: {
                                                    maxWidth: 991
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 22,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 767
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 10,
                                                    }],
                                                }
                                            },
                                            {
                                                query: {
                                                    maxWidth: 499
                                                },
                                                option: {
                                                    dataZoom: [{
                                                        start: 0,
                                                        end: 7,
                                                    }],
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Labs Results',
                    categories:[
                        {
                            group_title: 'Labs',
                            items:[
                                {
                                    name: '25 Hydroxy Vitamin D',
                                    is_selected: 0,
                                    img: 'hydroxy-d.svg',
                                    title: '25 Hydroxy Vitamin D',
                                    subtext: 'What\'s your 25 hydroxy vitamin D level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ng/mL',
                                        options: [
                                            { value: 'ng/mL', label: 'ng/mL' },
                                            { value: 'nmol/L', label: 'nmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Blood Glucose',
                                    is_selected: 0,
                                    img: 'blood-glucose.svg',
                                    title: 'Blood Glucose',
                                    subtext: 'What\'s your blood glucose level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mg/dL',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'C Reactive Protein',
                                    is_selected: 0,
                                    img: 'c-reactive-protein.svg',
                                    title: 'C Reactive Protein',
                                    subtext: 'What\'s your C reactive protein level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ng/mL',
                                        options: [
                                            { value: 'ng/mL', label: 'ng/mL' },
                                            { value: 'pmol/L', label: 'pmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Ferritin',
                                    is_selected: 0,
                                    img: 'ferritin.svg',
                                    title: 'Ferritin',
                                    subtext: 'What\'s your ferritin level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ng/mL',
                                        options: [
                                            { value: 'ng/mL', label: 'ng/mL' },
                                            { value: 'pmol/L', label: 'pmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'HDL Cholesterol',
                                    is_selected: 0,
                                    img: 'hdl-cholesterol.svg',
                                    title: 'HDL Cholesterol',
                                    subtext: 'What\'s your HDL cholesterol level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mg/dL',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'LDL Cholesterol',
                                    is_selected: 0,
                                    img: 'ldl-cholesterol.svg',
                                    title: 'LDL Cholesterol',
                                    subtext: 'What\'s your LDL cholesterol level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mg/dL',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Total Cholesterol',
                                    is_selected: 0,
                                    img: 'cholesterol.svg',
                                    title: 'Total Cholesterol',
                                    subtext: 'What\'s your total cholesterol level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mg/dL',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Hematocrit',
                                    is_selected: 0,
                                    img: 'hematocrit.svg',
                                    title: 'Hematocrit',
                                    subtext: 'What\'s your hematocrit level?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                            { value: 'mL/dL', label: 'mL/dL' },
                                            { value: 'L/L', label: 'L/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Hemoglobin',
                                    is_selected: 0,
                                    img: 'hemoglobin.svg',
                                    title: 'Hemoglobin',
                                    subtext: 'What\'s your hemoglobin level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'g/dL',
                                        options: [
                                            { value: 'g/dL', label: 'g/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Hemoglobin A1C',
                                    is_selected: 0,
                                    img: 'hemoglobin-a1c.svg',
                                    title: 'Hemoglobin A1C',
                                    subtext: 'What\'s your hemoglobin A1C level?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Insulin',
                                    is_selected: 0,
                                    img: 'insulin.svg',
                                    title: 'Insulin',
                                    subtext: 'What\'s your insulin level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mcU/mL',
                                        options: [
                                            { value: 'mcU/mL', label: 'mcU/mL' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Ketones(Blood)',
                                    is_selected: 0,
                                    img: 'ketones-blood.svg',
                                    title: 'Ketones(Blood)',
                                    subtext: 'What\'s your blood ketone level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mmol/L',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Ketones(Breath)',
                                    is_selected: 0,
                                    img: 'ketones-breath.svg',
                                    title: 'Ketones(Breath)',
                                    subtext: 'What\'s your breath ketone level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'ppm',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                            { value: 'ppm', label: 'ppm' },
                                            { value: 'ACEs', label: 'ACEs' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Ketones(Urine)',
                                    is_selected: 0,
                                    img: 'ketones-urine.svg',
                                    title: 'Ketones(Urine)',
                                    subtext: 'What\'s your urine ketone level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mmol/L',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Oxygen Saturation',
                                    is_selected: 0,
                                    img: 'o2-saturation.svg',
                                    title: 'Oxygen Saturation',
                                    subtext: 'What\'s your oxygen saturation level?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Triglycerides',
                                    is_selected: 0,
                                    img: 'triglycerides.svg',
                                    title: 'Triglycerides',
                                    subtext: 'What\'s your triglycerides level?',
                                    unit: {
                                        mode: 'single',
                                        value: 'mg/dL',
                                        options: [
                                            { value: 'mg/dL', label: 'mg/dL' },
                                            { value: 'mmol/L', label: 'mmol/L' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Chellenges',
                    categories:[
                        {
                            group_title: 'Chellenges',
                            items:[
                                {
                                    name: 'Push-ups',
                                    is_selected: 0,
                                    img: 'push-up.svg',
                                    title: 'Push-ups',
                                    subtext: 'How many push-ups have you done today?',
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Sets',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Set ${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Reps',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Rep ${params.value}`;
                                                }
                                            },
                                            data: [25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 50},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 50 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Rest',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Res ${params.value}`;
                                                }
                                            },
                                            data: [20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 45},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 45 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Pull-ups',
                                    is_selected: 0,
                                    img: 'pull-up.svg',
                                    title: 'Pull-ups',
                                    subtext: 'How many pull-ups have you done today?',
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Sets',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Set ${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Reps',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Rep ${params.value}`;
                                                }
                                            },
                                            data: [25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 50},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 50 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Rest',
                                            type: 'bar',
                                            stack: 'total',
                                            label: {
                                                show: true,
                                                color: '#fff',
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `Res ${params.value}`;
                                                }
                                            },
                                            data: [20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15],
                                            barWidth: 70,
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 0,
                                                label: {
                                                    show: false,
                                                    fontSize: 10,
                                                    color: '#fff',
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 45},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 45 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Steps',
                                    is_selected: 0,
                                    img: 'steps.svg',
                                    title: 'Steps',
                                    subtext: 'How many steps did you take today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'steps',
                                        options: [
                                            { value: 'steps', label: 'Steps' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Running',
                                    is_selected: 0,
                                    img: 'running.svg',
                                    title: 'Running',
                                    subtext: 'How far did you walk today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'min',
                                        options: [
                                            { value: 'min', label: 'Minutes' },
                                            { value: 'hr', label: 'Hours' },
                                            { value: 'km', label: 'Kilometers' },
                                            { value: 'mi', label: 'Miles' },
                                        ],
                                    },
                                    average_score: 2,
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 55, 47, 40, 30, 25, 45, 40, 55, 47, 40, 30, 25, 45, 40, 55, 47, 40, 30, 25, 45, 40, 55, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 45},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 45 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                }
            ],
            selectScenario: 0,
            allAttr: 1,
        }
    },

    components:{
        TrackChart
    },

    props: {
        modelValue: Boolean,
    },

    emit: ['update:modelValue'],

    computed: {
        ...mapState({
            user: state => state.authModule.user,
        }),
    },
    
    watch: {
        modelValue (value) {
            if (value) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    methods:{
        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },
        toggleFunc(ev){
            let el = ev.currentTarget.closest('.content_card_wpr');
            const subEl = el.querySelectorAll('.list li');
            
            if (el.classList.contains('show')){
                el.classList.remove('show');
                for(let i = 0; i < subEl.length; i++){
                    subEl[i].classList.remove('show');
                }
            } else {
                el.classList.add('show');
            }
        },
        showDetails(ev){
            const el = ev.currentTarget.closest('li');
            if(el.classList.contains('show')){
                el.classList.remove('show');
            }else{
                el.classList.add('show');
            }
        },
        closeProgressDuration(){
            const vm = this;
            vm.progressDuration = false;
        },
        closeScenarioDropdown(){
            const vm = this;
            vm.scenarioDropdown = false;
        },
        closeOpenFilter(){
            const vm = this;
            vm.openFilter = false;
        },
        scrollingTo (el) {
            let targetElm = document.querySelector(`.preview_area .tabs_content #${el}`);

            setTimeout(function() {
                if (targetElm && targetElm.offsetTop) {
                    let top = targetElm.offsetTop - 50;
                    document.querySelector('.preview_area .tabs_content').scrollTo(0, top);
                }
            }, 100);
        },
    }
}
</script>

<style scoped>
.stat_container{
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto 70px auto;
}
.section_header h2{
    margin-right: auto;
}
.section_header h2 .tag{
    background: #e9e9e9;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-left: 10px;
}
.section_header .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}
.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}
.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}
.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}
.report_card {
    border-radius: 5px;
    margin-bottom: 40px;
    background: #fff;
}
.report_card .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
}
.report_card .user_info {
    display: flex;
    text-align: left;
    align-items: center;
}
.report_card .user_info img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #ccc;
}
.report_card .header h4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 3px;
}
.report_card .header h5 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin-bottom: 0;
}
.report_card .total_progress{
    display: flex;
    flex-wrap: wrap;
}
.report_card .total_progress li{
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
}
.report_card .total_progress label{
    padding: 2px 8px;
    display: block;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
}
.report_card .total_progress label.completed{
    background: #e8fdea;
    color: #30C13E;
}
.report_card .total_progress label.missed{
    background: #ffefef;
    color: #fb1414;
}
.report_card .total_progress h3{
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    color: #121525;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
}
.report_card .total_progress h3 span{
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin: 0 0 2px 5px;
}
.report_card .overview{
    background: #fff;
    border-radius: 10px;
}
.overview ul{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}
.overview ul li{
    padding: 15px;
    width: 33.333%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
}
.overview li h4 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.overview li h4 span{
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    color: #666;
    padding-top: 4px;
}
.overview li h4 span a{
    color: #121525;
    font-weight: 500;
}
.overview li img{
    max-width: 50px;
    height: auto;
    padding-right: 15px;
}
.overview li .score{
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}
.overview li .score span{
    font-size: 8px;
}
.progress_filter_bar{
    width: 190px;
    min-height: 270px;
}
.progress_filter_bar::-webkit-scrollbar{
    display: none;
}
.progress_filter_bar.show{
    right: 0;
}
.progress_filter_bar h4{
    font-size: 15px;
    line-height: 22px;
    color: #121525;
    font-weight: 500;
    padding: 0px 20px 5px;
    display: flex;
    align-items: center;
}
.progress_filter_bar h4 .close_btn{
    font-size: 13px;
    color: #999999;
    margin-left: auto;
    cursor: pointer;
}
.progress_filter_bar .scenario_box{
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 25px 8px 10px;
    margin: 15px;
    cursor: pointer;
}
.progress_filter_bar .scenario_box .dropdown_wpr{
    max-height: 170px;
    overflow-y: auto;
}
.progress_filter_bar .scenario_box .dropdown_wpr::-webkit-scrollbar{
    width: 4px;
}
.progress_filter_bar .scenario_box .dropdown_wpr::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background: #dbdbdb;
}
.progress_filter_bar .scenario_box i{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}
.progress_filter_bar .toggle_btn{
    display: flex;
    justify-content: flex-end;
    padding: 6px 20px 12px;
}
.progress_filter_bar .toggle_btn a{
    padding: 4px 10px;
    background: rgb(245, 245, 245);
    font-size: 10px;
    line-height: 12px;
    color: rgb(18, 21, 37);
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}
.progress_filter_bar .category_wpr{
    max-height: 400px;
    overflow-y: auto;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar{
    width: 4px;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.progress_filter_bar .category_wpr .category_grp{
    border-top: 1px solid #f5f5f5;
}
.progress_filter_bar .category_wpr .category_grp h4{
    padding: 12px 15px;
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.progress_filter_bar .category_wpr .category_grp h4 i{
    margin-left: auto;
    font-size: 11px;
    color: #999999;
    transition: all 0.3s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp ul{
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp.active ul{
    max-height: 10000px;
}
.progress_filter_bar .category_wpr ul li{
    padding: 10px 0;
    border-radius: 5px;
}
.progress_filter_bar .category_wpr ul li label.checkbox{
    justify-content: flex-start;
}
.progress_filter_bar .category_wpr ul li label.checkbox h5{
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}
.progress_filter_bar .category_wpr ul li label.checkbox span i{
    font-size: 10px;
    margin: 0;
}
.progress_filter_bar .category_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}

.content_card_wpr{
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.03);
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
}
.content_card_wpr .title{
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}
.content_card_wpr .title span{
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #777;
    margin-left: auto;
}
.content_card_wpr .title h4{
    font-size: 16px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.content_card_wpr .title h4 i{
    font-size: 14px;
    color: #2f7eed;
}
.content_card_wpr .title h4 label{
    font-size: 11px;
    line-height: 20px;
    border-radius: 12px;
    color: #2f7eed;
    background: #e3eefd;
    font-weight: 500;
    display: inline-block;
    padding: 0 6px;
}
.content_card_wpr .title .rate{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
}
.content_card_wpr .title .rate .score, .tracking_list li .score{
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}
.content_card_wpr .title .rate .score span, .tracking_list li .score span{
    font-size: 8px;
}

.content_card_wpr .title .rate h6{
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 400;
    margin-top: 5px;
    max-width: 100px;
    text-align:center;
    display: none;
}
.content_card_wpr .title .toggle_btn{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}
.content_card_wpr.show .title .toggle_btn{
    transform: rotate(-180deg);
}
.content_card_wpr .section_content{
    padding: 0;
    width: 100%;
    max-height: 55px;
    transition: all 0.5s ease-in-out;
}
.content_card_wpr.show .section_content{
    max-height: 1000vh;
    overflow: overlay;
}
.content_card_wpr.show .section_content::-webkit-scrollbar{
    width: 3px;
}
.content_card_wpr.show .section_content::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.section_content .list > li{
    display: flex;
    padding: 0 30px;
    min-height: 55px;
    /* border-bottom: 1px solid #f5f5f5; */
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}
.workout_card li .info_wpr{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    position: relative;
    padding-bottom: 15px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}
.workout_card li.show .info_wpr{
    gap: 15px;
}
.workout_card li.show .info_wpr h4 i{
    transform: rotate(-180deg);
}
.workout_card li a{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
    margin: 0 0 0 auto;
    cursor: pointer;
}
.workout_card li.show .info_wpr .details{
    max-height: 1000px;
}
.workout_card li:last-child .info_wpr{
    border-bottom: 0;
}
.workout_card li .card_wrapper{
    border-radius: 6px;
    display: flex;
    gap: 15px;
    cursor: pointer;
    padding: 15px 0 0 0;
    width: 100%;
}
.workout_card li .card_wrapper svg{
    flex: 0 0 20px;
    height: 20px;
}
.workout_card li .info_wpr h4{
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.workout_card li .info_wpr h4 .date{
    font-weight: 500;
    color: #121525;
    padding-right: 10px;
}
.workout_card li .info_wpr span.tag{
    background: #ecf4ff;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    color: #2f7eed;
    margin-left: 6px;
}
.workout_card li .info_wpr h4 i{
    font-size: 10px;
    margin-left: auto;
    color: #999;
    transition: all 0.3s ease-in-out;
    display: none;
}
.content_card_wpr.show .workout_card li .info_wpr h4 i{
    display: block;
}
.workout_card li .info_wpr h6{
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}
.workout_card li .info_wpr h6 span{
    padding: 0 5px 0 0;
}
.workout_card li .info_wpr h6 span:not(:last-child){
    border-right: 1px solid #d9d9d9;
}
.workout_card li .info_wpr .blocks{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1 1 auto;
}
.workout_card li .info_wpr .check_list{
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}
.workout_card li .info_wpr .check_list li{
    width: 150px;
    padding: 5px;
}
.workout_card li .info_wpr label.checkbox{
    justify-content: flex-start;
}
.workout_card li .info_wpr label.checkbox span i{
    transform: scale(1);
    opacity: 1;
}
.workout_card li .info_wpr .checkbox p{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    padding-left: 10px;
}
.workout_card li .info_wpr .todo_list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}
.workout_card li .info_wpr .todo_list li{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}
.workout_card li .info_wpr .todo_list li label {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    color: #777;
    font-size: 9px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 2px;
}
.workout_card li .info_wpr .todo_list li .task_ans{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box{
    display: flex;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box.disabled{
    opacity: 0.5;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_box{
    width: 60px;
    border: 1px solid #b2eabf;
    background: #effff2;
    color: #23993e;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_box i{
    font-size: 10px;
    margin-top: 1px;
}
.workout_card li .info_wpr .todo_list li .task_ans .no_box{
    width: 60px;
    border: 1px solid #ffb4b4;
    background: #ffecec;
    color: #eb1414;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .no_box i{
    font-size: 10px;
    margin-top: 3px;
}
.workout_card li .info_wpr .todo_list li .task_ans .ans_text{
    min-width: 50px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
}


.workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}
.workout_card li .info_wpr .details .redirect_btn{
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}
.workout_card li .info_wpr .details .redirect_btn.missed{
    color: #eb1414;
}
.workout_card li .info_wpr .details .submit_details{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.workout_card li .info_wpr .details .submit_details label{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}
.workout_card li .info_wpr .details .submit_details span{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #2f7eed;
    background: #f0f6ff;
    padding: 4px 10px;
    border-radius: 12px;
}
.workout_card li .info_wpr .details .submit_details span.delay{
    color: #f2a31d;
    background: #fff6e6;
}
.workout_card li .info_wpr .details .submit_details span.success{
    color: #23993e;
    background: #effff2;
}
.workout_card li .info_wpr .details .submit_details span.missed{
    color: #eb1414;
    background: #fff2f2;
}
.workout_card li.show .info_wpr .details{
    max-height: 1000px;
}

@media(max-width: 767px){
    .report_card .header{
        padding: 20px;
    }
    /* .report_card .header h3 {
        font-size: 13px !important;
        line-height: 17px !important;
        display: block;
    } */
    .overview ul li{
        width: 50%;
    }
}
@media(max-width: 599px){
    .report_card .header{
        flex-wrap: wrap;
    }
    .overview ul li{
        width: 100%;
    }
    .report_card .total_progress{
        flex-direction: column;
        align-items: flex-end;
    }
    .report_card .total_progress li{
        padding: 2px 0;
    }
}
@media(max-width: 499px){
    .report_card .user_info,
    .report_card .total_progress{
        width: 100%;
    }
    .report_card .total_progress{
        margin-top: 10px;
        flex-direction: row;
    }
    .report_card .total_progress li{
        margin-left: 0;
        margin-right: 20px;
    }
    .report_card .header h3 span{
        display: block;
        font-size: 11px;
        line-height: 15px;
    }
    .stat_container .score_circle {
        transform: scale(1);
    }
}
</style>